import { Route } from '@angular/router';

export const ROBOT_ROUTES: Route[] = [
    {
        path: 'stores',
        title: 'Аптеки',
        data: {
            isNav: true,
            navTitle: 'Аптеки',
            permissionId: 'pricebot',
        },
        loadComponent: () =>
            import('./stores/stores.component').then((m) => m.StoresComponent),
    },
    {
        path: 'sales',
        title: 'Продажи',
        data: {
            isNav: true,
            navTitle: 'Продажи',
            permissionId: 'pricebot',
        },
        loadComponent: () =>
            import('./sales/sales.component').then((m) => m.SalesComponent),
    },
    {
        path: 'stale-sales',
        title: 'Неликвиды',
        data: {
            isNav: true,
            navTitle: 'Неликвиды',
            permissionId: 'pricebot',
            isStale: true,
        },
        loadComponent: () =>
            import('./sales/sales.component').then((m) => m.SalesComponent),
    },
    {
        path: 'contract-sales',
        title: 'Контрактные',
        data: {
            isNav: true,
            navTitle: 'Контрактные',
            permissionId: 'pricebot',
            isContract: true,
        },
        loadComponent: () =>
            import('./sales/sales.component').then((m) => m.SalesComponent),
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: '/optimus-price/stores',
    },
];
